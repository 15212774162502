import { MouseEvent, RefObject } from 'react';
import { Link } from 'components/Link';

import { curlyQuotes } from 'utils/text';
import cx from 'classnames';
import useCheckExternalLink from 'utils/useCheckExternalLink';

interface ButtonProps {
  className: string;
  containerClassName: string;
  style: React.CSSProperties;
  variant: 'primary' | 'no-style';
  type: 'button' | 'submit' | 'reset';
  label: string;
  ariaLabel: string;
  to: string;
  isAnchor: boolean;
  openInCurrentTab: boolean;
  openInNewTab: boolean;
  onClick(e: MouseEvent<HTMLElement>): void;
  onMouseEnter(e: MouseEvent<HTMLElement>): void;
  onMouseLeave(e: MouseEvent<HTMLElement>): void;
  onTouchStart(e: MouseEvent<HTMLElement>): void;
  elemRef: RefObject<HTMLAnchorElement | HTMLButtonElement>;
  wrap: boolean;
  disabled: boolean;
  inlineFlex: boolean;
  children?: React.ReactNode;
}

type Props = Partial<ButtonProps>;

const Button: React.FC<Props> = ({
  className,
  containerClassName,
  style = {},
  variant,
  type,
  label,
  ariaLabel,
  children,
  to = '',
  isAnchor = false,
  openInCurrentTab = false,
  openInNewTab = false,
  onClick = () => {},
  onMouseLeave = () => {},
  onMouseEnter = () => {},
  elemRef,
  wrap,
  inlineFlex = true,
  disabled,
}) => {
  const isExternalLink = useCheckExternalLink(to);

  const classes = cx(`Button pointer p0`, className, {
    [`Button--style-${variant}`]: !!variant,
    'Button--wrap': wrap,
  });

  const linkIsMailOrTel = to.includes('mailto:') || to.includes('tel:');

  const linkedComponent =
    linkIsMailOrTel || isExternalLink || openInNewTab || isAnchor ? (
      <a
        className={cx('Button text-decoration-none', containerClassName, {
          'events-none': disabled,
        })}
        target={openInCurrentTab || linkIsMailOrTel ? '_self' : '_blank'}
        href={to}
        rel="noopener noreferrer"
        onClick={onClick}
        aria-label={ariaLabel}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        ref={elemRef as RefObject<HTMLAnchorElement>}
      >
        <div className={classes}>
          {!!children && !label ? (
            children
          ) : label ? (
            <span className="flex justify-center items-center">
              {curlyQuotes(label)}
            </span>
          ) : null}
        </div>
      </a>
    ) : (
      <Link
        className={cx('text-decoration-none', containerClassName, {
          'events-none': disabled,
          'inline-flex': inlineFlex,
        })}
        aria-label={ariaLabel}
        to={to}
        onClick={onClick}
        style={style}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        ref={elemRef as RefObject<HTMLAnchorElement>}
      >
        <div className={cx('Button', classes)}>
          {!!children && !label ? (
            children
          ) : label ? (
            <span className="flex justify-center items-center">
              {curlyQuotes(label)}
            </span>
          ) : null}
        </div>
      </Link>
    );

  const button = !!to ? (
    linkedComponent
  ) : (
    <button
      aria-label={ariaLabel}
      type={type}
      onClick={onClick}
      className={cx('flex justify-center items-center', classes, {
        'events-none': disabled,
      })}
      style={style}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      ref={elemRef as RefObject<HTMLButtonElement>}
      disabled={disabled}
    >
      {!!children && !label ? children : label ? curlyQuotes(label) : null}
    </button>
  );

  return button;
};

export default Button;
