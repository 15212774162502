import get from 'lodash/get';

import { Podcast } from 'sharedTypes';

const sanitizePodcastResponse = (data: unknown): Podcast => {
  return {
    _type: get(data, '_type', 'podcast'),
    id: get(data, 'id', ''),
    slug: get(data, 'slug', ''),
    date: get(data, 'date', ''),
    title: get(data, 'title', ''),
    dek: get(data, 'dek', ''),
    description: get(data, 'description', ''),
    credits: get(data, 'credits', []),
    subscribeLink: get(data, 'subscribeLink', ''),
    spotifyPodcastLink: get(data, 'spotifyPodcastLink', ''),
    rssLink: get(data, 'rssLink', ''),
    amazonPodcastLink: get(data, 'amazonPodcastLink', ''),
    amazonPodcastText: get(data, 'amazonPodcastText', ''),
    image: {
      src: get(data, 'image.src', ''),
      caption: get(data, 'image.caption', ''),
      credit: get(data, 'image.credit', ''),
      id: get(data, 'image.id', ''),
      alt: get(data, 'image.alt', ''),
      metadata: get(data, 'image.metadata'),
    },
    seo: {
      title: get(data, 'seo.title', ''),
      description: get(data, 'seo.description', ''),
      image: {
        src: get(data, 'seo.image.src', ''),
        caption: get(data, 'seo.image.caption', ''),
        credit: get(data, 'seo.image.credit', ''),
        id: get(data, 'seo.image.id', ''),
        alt: get(data, 'seo.image.alt', ''),
        metadata: get(data, 'seo.image.metadata'),
      },
    },
  };
};

export default sanitizePodcastResponse;
