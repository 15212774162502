import SectionLinkGroq from './SectionLinkGroq';
import ImageGroq from './ImageGroq';

const ArticleLinkGroq = `{
  "id": _id,
  _type,
  title,
  excerpt,
  brief,
  isFiction,
  isHighDefImage,
  authors[]->{ firstName, lastName, slug, legacySlug },
  'featuredImage': featuredImage${ImageGroq},
  'heroImage': heroImage${ImageGroq},
  section->${SectionLinkGroq},
  "releaseDate": coalesce(releaseDate, _createdAt),
  "createdAt": _createdAt,
  slug,
  'quote': featuredQuote, 
  'fact': featuredFact,
  dek
}`;

export const ArticleLinkPreviewGroq = `{
  "id": _id,
  title,
  excerpt,
  brief,
  isFiction,
  isHighDefImage,
  authors[]->{ firstName, lastName, slug, legacySlug },
  'featuredImage': featuredImage${ImageGroq},
  'heroImage': heroImage${ImageGroq},
  releaseDate,
  "createdAt": _createdAt,
  slug,
  'quote': featuredQuote, 
  'fact': featuredFact,
  dek
}`;

export const RecentArticleLinkGroq = `{
  "id": _id,
  _type,
  title,
  isFiction,
  authors[]->{ firstName, lastName, slug, legacySlug },
  section->${SectionLinkGroq},
  "releaseDate": coalesce(releaseDate, _createdAt),
  "createdAt": _createdAt,
  slug,
}`;

export default ArticleLinkGroq;
