import ImageGroq from './ImageGroq';

const PodcastGroq = `{
  "id": _id, 
  slug, 
  "date": _createdAt, 
  title, 
  "applePodcastLink": subscribeLink,
  spotifyPodcastLink,
  rssLink,
  amazonPodcastLink,
  amazonPodcastText,
  dek, 
  description, 
  credits,
  subscribeLink,
  "image": image${ImageGroq},
  "seo": {
    "title": seoTitle,
    "description": seoDescription,
    "image": seoImage${ImageGroq}
  }
}`;

export default PodcastGroq;
