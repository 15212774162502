import { useReducer } from 'react';
import { Theme } from 'types';

/**
 * Actions
 */
enum ActionType {
  SetTheme = 'SET_THEME',
  SetSideNav = 'OPEN_SIDE_NAV',
  SetSectionNav = 'CLOSE_SIDE_NAV',
  SetSearchBar = 'OPEN_SEARCH_BAR',
  SetScrollLock = 'IS_SCROLL_LOCKED',
}

interface SetThemeAction {
  type: ActionType.SetTheme;
  theme: Theme;
}

interface SetSideNavAction {
  type: ActionType.SetSideNav;
  isOpen: boolean;
}

interface SetSectionNavAction {
  type: ActionType.SetSectionNav;
  isOpen: boolean;
}

interface SetSearchBarAction {
  type: ActionType.SetSearchBar;
  isOpen: boolean;
}

interface SetScrollLockAction {
  type: ActionType.SetScrollLock;
  isActive: boolean;
}

type Action =
  | SetThemeAction
  | SetSideNavAction
  | SetSectionNavAction
  | SetSearchBarAction
  | SetScrollLockAction;

/**
 * State
 */

interface State {
  theme: Theme;
  sideNavIsOpen: boolean;
  sectionNavIsOpen: boolean;
  searchIsOpen: boolean;
  scrollLockIsActive: boolean;
}

const initialState: State = {
  theme: Theme.Default,
  sideNavIsOpen: false,
  sectionNavIsOpen: false,
  searchIsOpen: false,
  scrollLockIsActive: false,
};

/**
 * Reducer
 */

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.SetTheme:
      return {
        ...state,
        theme: action.theme,
      };
    case ActionType.SetSideNav:
      return {
        ...state,
        sideNavIsOpen: action.isOpen,
      };
    case ActionType.SetSectionNav:
      return {
        ...state,
        sectionNavIsOpen: action.isOpen,
      };
    case ActionType.SetSearchBar:
      return {
        ...state,
        searchIsOpen: action.isOpen,
      };
    case ActionType.SetScrollLock:
      return {
        ...state,
        scrollLockIsActive: action.isActive,
      };
    default:
      // @ts-expect-error
      throw new Error(`type "${action.type}" is not a valid action`);
  }
};

/**
 * Hook
 */

export const useUiState = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setTheme = (theme: Theme) =>
    dispatch({ type: ActionType.SetTheme, theme });

  const openSectionNav = () =>
    dispatch({ type: ActionType.SetSectionNav, isOpen: true });
  const closeSectionNav = () =>
    dispatch({ type: ActionType.SetSectionNav, isOpen: false });
  const openSideNav = () =>
    dispatch({ type: ActionType.SetSideNav, isOpen: true });
  const closeSideNav = () =>
    dispatch({ type: ActionType.SetSideNav, isOpen: false });
  const openSearchBar = () =>
    dispatch({ type: ActionType.SetSearchBar, isOpen: true });
  const closeSearchBar = () =>
    dispatch({ type: ActionType.SetSearchBar, isOpen: false });
  const lockScroll = () =>
    dispatch({ type: ActionType.SetScrollLock, isActive: true });
  const unlockScroll = () =>
    dispatch({ type: ActionType.SetScrollLock, isActive: false });

  return {
    ...state,
    setTheme,
    openSectionNav,
    closeSectionNav,
    openSideNav,
    closeSideNav,
    openSearchBar,
    closeSearchBar,
    lockScroll,
    unlockScroll,
  };
};
