import get from 'lodash/get';
import memoize from 'lodash/memoize';

import { ArticleLink, SectionLink } from 'sharedTypes';

const sanitizeArticleLink = (article: unknown): ArticleLink => {
  return {
    id: get(article, 'id', ''),
    _type: get(article, '_type', 'article'),
    title: get(article, 'title', ''),
    heroImage: {
      src: get(article, 'heroImage.src', ''),
      caption: get(article, 'heroImage.caption', ''),
      credit: get(article, 'heroImage.credit', ''),
      id: get(article, 'heroImage.id', ''),
      alt: get(article, 'heroImage.alt', ''),
      metadata: get(article, 'heroImage.metadata'),
      crop: get(article, 'heroImage.crop'),
    },
    featuredImage: {
      src: get(article, 'featuredImage.src', ''),
      caption: get(article, 'featuredImage.caption', ''),
      credit: get(article, 'featuredImage.credit', ''),
      id: get(article, 'featuredImage.id', ''),
      alt: get(article, 'featuredImage.alt', ''),
      metadata: get(article, 'featuredImage.metadata'),
      crop: get(article, 'featuredImage.crop'),
    },
    section: get(article, 'section', {} as SectionLink),
    authors: get(article, 'authors', []),
    excerpt: get(article, 'excerpt', ''),
    releaseDate:
      get(article, 'releaseDate', '') || get(article, 'createdAt', ''),
    slug: get(article, 'slug', ''),
    quote: get(article, 'quote'),
    fact: get(article, 'fact'),
    dek: get(article, 'dek'),
    brief: get(article, 'brief'),
  };
};

export default sanitizeArticleLink;
