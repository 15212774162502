import get from 'lodash/get';
import memoize from 'lodash/memoize';
import { ErrorWithContext } from 'utils/errors';

import {
  Article,
  ArticleHeroType,
  Collection,
  Column,
  SectionLink,
} from 'sharedTypes';

export default memoize((data: unknown): Article => {
  if (
    !get(data, 'id') ||
    !get(data, 'title') ||
    !get(data, 'slug') ||
    !get(data, 'meta.authors') ||
    !get(data, 'meta.section') ||
    !get(data, 'meta.tags')
  ) {
    throw new ErrorWithContext('Could not parse Article data', {
      article: data,
    });
  }

  return {
    _type: get(data, '_type', 'article'),
    title: get(data, 'title', ''),
    slug: get(data, 'slug', ''),
    id: get(data, 'id', ''),
    section: get(data, 'section', {} as SectionLink),
    collection: get(data, 'collection', {} as Collection),
    column: get(data, 'column', {} as Column),
    seo: {
      title: get(data, 'seo.title', ''),
      description: get(data, 'seo.description', ''),
      image: {
        src: get(data, 'seo.image.src', ''),
        caption: get(data, 'seo.image.caption', ''),
        credit: get(data, 'seo.image.credit', ''),
        id: get(data, 'seo.image.id', ''),
        alt: get(data, 'seo.image.alt', ''),
        metadata: get(data, 'seo.image.metadata'),
        crop: get(data, 'seo.image.crop'),
      },
    },
    meta: {
      date:
        get(data, 'meta.releaseDate', '') || get(data, 'meta.createdAt', ''),
      authors: get(data, 'meta.authors', []),
      section: get(data, 'meta.section', {} as SectionLink),
      tags: get(data, 'meta.tags', []),
      excerpt: get(data, 'meta.excerpt', ''),
      isFiction: get(data, 'meta.isFiction', false),
      isHighDefImage: get(data, 'meta.isHighDefImage', false),
      brief: get(data, 'meta.brief', ''),
      createdAt: get(data, 'meta.createdAt', ''),
      updatedAt: get(data, 'meta.updatedAt', ''),
      releaseDate: get(data, 'meta.releaseDate', ''),
      articleType: get(data, 'meta.articleType', ''),
      relatedRecipes: get(data, 'meta.relatedRecipes', []),
      relatedRecipesDisplay: get(data, 'meta.relatedRecipesDisplay'),
      legacyRecipeMigration: get(data, 'meta.legacyRecipeMigration', {}),
    },
    hero: {
      heroImage: {
        src: get(data, 'hero.heroImage.src', ''),
        rteCredit: get(data, 'hero.heroImage.rteCredit'),
        caption: get(data, 'hero.heroImage.caption', ''),
        credit: get(data, 'hero.heroImage.credit', ''),
        id: get(data, 'hero.heroImage.id', ''),
        alt: get(data, 'hero.heroImage.alt', ''),
        metadata: get(data, 'hero.heroImage.metadata'),
        crop: get(data, 'hero.heroImage.crop'),
      },
      heroType: get(
        data,
        'hero.heroType',
        ArticleHeroType.HALF_WIDTH_HEADER_WITH_IMAGE
      ),
      dek: get(data, 'hero.dek', ''),
    },
    content: {
      body: get(data, 'content.body', []),
    },
    featuredContent: {
      featuredImage: get(data, 'featuredContent.featuredImage'),
      featuredQuote: get(data, 'featuredContent.featuredQuote', null),
      featuredFact: get(data, 'featuredContent.featuredFact', null),
    },
    legacy: {
      isLegacy: get(data, 'legacy.legacyArticlePathname', false),
      legacyPathname: get(data, 'legacy.legacyArticlePathname', ''),
    },
    notes: {
      editorsNote: get(data, 'notes.editorsNote', []),
      endNote: get(data, 'notes.endNote', []),
    },
  };
});
